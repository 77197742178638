
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
export default defineComponent({
  name: "ExistingCoursesUnderCurriculumPage",
  components: { BaseTableFiltered },
  data: () => ({
    search: "",
    headers: [
      { text: "Kurs-ID", value: "id" },
      {
        text: "Studieplan-ID",
        value: "curriculumId",
      },
      { text: "Navn", value: "courseName" },
      {
        text: "Arrangør",
        value: "organizerOrganizationName",
      },
      {
        text: "Finansiert av",
        value: "financedByOrganizationName",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
  }),
  computed: {
    ...mapGetters("courses", ["getCourses"]),
  },
  methods: {
    ...mapActions("courses", ["getCoursesByStudId"]),
    newCourse() {
      this.$store.commit("courses/SET_COURSE_INFO", {
        curriculumId: this.$route.params.id,
      });
      this.$router.push({
        name: CourseRouteNames.NewCourse,
        params: { studyId: this.$route.params.id },
      });
    },
  },
  async mounted() {
    await this.getCoursesByStudId(this.$route.params.id);
  },
});
