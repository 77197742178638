var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.getCourses,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          }
        }, [_vm._v("Eksisterende kurs")]), _c('v-spacer'), _c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mx-2",
                attrs: {
                  "fab": "",
                  "dark": "",
                  "small": "",
                  "color": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.newCourse();
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "dark": ""
                }
              }, [_vm._v(" mdi-plus ")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("Nytt kurs")])])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }